@tailwind base;
@tailwind components;
@tailwind utilities;

/* adding fonts below */
@font-face {
    font-family: "GoathamNarrow";
    src: url(./assets/fonts/GothamNarrow-Thin.otf) format("truetype");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "GoathamNarrow";
    src: url(./assets/fonts/GothamNarrow-XLight.otf) format("truetype");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "GoathamNarrow";
    src: url(./assets/fonts/GothamNarrow-Light.otf) format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "GoathamNarrow";
    src: url(./assets/fonts/GothamNarrow-Book.otf) format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "GoathamNarrow";
    src: url(./assets/fonts/GothamNarrow-Medium.otf) format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "GoathamNarrow";
    src: url(./assets/fonts/GothamNarrow-Bold.otf) format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "EdoSZ";
    src: url(./assets/fonts/edosz.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica";
    src: url(./assets/fonts/Helvetica-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}
  
.brush-style{
    font-family: "EdoSZ", "GothamNarrow", sans-serif !important;
}
/* adding fonts above */

html, body, #root{
    height: 100%;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
   @apply !bottom-[90px] sm:!bottom-24
}
.swiper-pagination-bullet{
    @apply !h-3 !w-3 border-2 !border-gray-200 !opacity-100 !bg-transparent !z-[100]
  }
.swiper-pagination-bullet{
    background: rgb(230, 230, 230) !important;
    opacity: 0.9 !important
  }
  .swiper-pagination-bullet-active{
    @apply !bg-white
  }

  .swiper-button-next:after, .swiper-button-prev:after{
    @apply text-white
  }

  .helvetica-font{
    font-family: "Helvetica" sans-serif !important;
  }

  @media screen and (max-width: 768px)
{
  .modal-video-movie-wrap{
    height: 80% !important ;
  }
}

.swiper-button-next {
  margin-right: 30px;
}
.swiper-button-prev{
  margin-left: 30px;
}