.modal-video-inner{
  padding: 0px 0px;
}

.modal-video{
  background-color: rgb(0, 0, 0,1) !important
}


@media (prefers-reduced-motion: no-preference) {
    .sound-icon-rounded-text {
        animation: spin 6s infinite linear;  
    }
    @keyframes spin {
      to {
        rotate: 360deg;
      }
    }
  }